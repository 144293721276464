import React from 'react';
import { Invoice } from 'bitcoin-invoice-components';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { BITBOX } from 'bitbox-sdk';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Input,
  InputLabel,
  Button,
  H3,
  Tabs,
} from '@bitcoin-portal/bitcoincom-storybook';
import merge from 'lodash/merge';
import {
  InputWrapper,
  OutputWrapper,
  Content,
  InputError,
  Buttons,
  RetryButtons,
  CustomContentBlock,
  CheckLabel,
  Red,
  Form,
  Feedback,
  DividendDetails,
  ProgressContainer,
  ErrorContainer,
  TextArea,
  Small,
  Label,
  CustomOL,
  Instructions,
  InvoiceWrapper,
  InvoiceTable,
  CustomLink,
  CustomSelect,
} from './styled';

const SLPSDK = require('slp-sdk');

const SLP = new SLPSDK();

const slpList = require('slp-list');

const Big = require('big.js');

const bitbox = new BITBOX();

const inputState = { untouched: 0, valid: 1, invalid: 2 };

class Airdropper extends React.Component {
  constructor(props) {
    super(props);
    this.initialFormData = {
      airdropSlpTokenId: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      receivingSlpTokenId: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      dividendSlp: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      customBlockheight: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
      excludedAddresses: {
        value: '',
        state: inputState.untouched,
        error: null,
      },
    };
    this.initialTokenInfo = {
      minSlpBalanceForDividend: 0,
      circulatingSupply: 0,
      tokenId: '',
      streetName: '',
    };
    this.initialAirdropTokenInfo = {
      circulatingSupply: 0,
      tokenId: '',
      streetName: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTokenIdChange = this.handleTokenIdChange.bind(this);
    this.handleDividendChange = this.handleDividendChange.bind(this);
    this.handleExcludedAddressesChange = this.handleExcludedAddressesChange.bind(
      this,
    );
    this.reset = this.reset.bind(this);
    this.handleBackToStart = this.handleBackToStart.bind(this);
    this.throwTokenIdError = this.throwTokenIdError.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.copyUri = this.copyUri.bind(this);
    this.setTabState = this.setTabState.bind(this);
    this.parseInvoicePromises = this.parseInvoicePromises.bind(this);
    this.allInvoicesBuiltCheck = this.allInvoicesBuiltCheck.bind(this);
    this.getTokenHolderAddresses = this.getTokenHolderAddresses.bind(this);
    this.getBestBlockForSlpList = this.getBestBlockForSlpList.bind(this);
    this.validateBlockheight = this.validateBlockheight.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleBlockheightChange = this.handleBlockheightChange.bind(this);
    this.handleStepTwoButtonClick = this.handleStepTwoButtonClick.bind(this);
    this.handleAirdropSubmit = this.handleAirdropSubmit.bind(this);
    this.expireInvoices = this.expireInvoices.bind(this);
    this.handleSlpdbChange = this.handleSlpdbChange.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.toggleDividendOutput = this.toggleDividendOutput.bind(this);
    this.handleAddrExclude = this.handleAddrExclude.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      formData: merge({}, this.initialFormData),
      tokenInfo: merge({}, this.initialTokenInfo),
      airdropTokenInfo: merge({}, this.initialAirdropTokenInfo),
      effectiveCirculatingSupply: 0,
      effectiveMinSlpBalanceForDividend: 0,
      eligibleAddresses: 0,
      outputTx: [],
      txBuilt: false,
      loading: false,
      paymentIds: [],
      tab: false,
      limitError: null,
      apiError: null,
      calcError: null,
      slpdbError: null,
      appStatus: '',
      blockUsedInScan: null,
      selectedOption: 'lastConfirmedBlock',
      formStep: 'one',
      fetchingTokenInfo: false,
      invoicesExpired: '',
      slpdb: {
        value: 'https://slpdb.fountainhead.cash',
        label: 'slpdb.fountainhead.cash',
      },
      toggleAdvanced: false,
      toggleOutput: false,
      excludeAddresses: false,
      windowWidth: 0,
    };

    this.dustLimit = 5000;
  }

  setTabState(tab) {
    this.setState({
      tab,
    });
  }

  getTokenHolderAddresses(
    bestBlock,
    tokenInfo,
    minSlpBalanceForDividend,
    dividendSlp,
  ) {
    const {
      intl: { formatMessage },
    } = this.props;
    const queryLimit = 10000;
    const { tokenId, streetName } = tokenInfo;
    const { airdropTokenInfo, slpdb, excludeAddresses, formData } = this.state;
    const { decimals } = airdropTokenInfo;
    // prev const { tokenId, streetName, circulatingSupply } = tokenInfo; ... circulatingSupply is not needed though, only supply among eligible addresses
    const batchSize = 18; // max outputs for SLP transactions seems to be 19 in testing, using 15 to be conservative (update 20191114, set to 18 as badger wallet errors with 'cannot have more than 19 slp outputs'; and there is a change output, so 18+1)
    const slpdbUrl = slpdb.value;
    slpList.SlpdbQueries.GetAddressListFor(bestBlock, tokenId, slpdbUrl).then(
      result => {
        /*
        console.log(
          `Result from slpList.SlpdbQueries.GetAddressListFor(${bestBlock}, ${tokenId})`,
        );
        console.log(result);
        */
        // if you get an empty one back, slpdb is probably down, return slpdb error
        if (result.size === 0) {
          const slpdbError = (
            <FormattedMessage id="slp-dividend-calculator.errors.slpdbErrorTwo" />
          );
          return this.setState({
            loading: false,
            slpdbError,
          });
        }

        // Remove entries where token balance is too small for dividend
        if (excludeAddresses && formData.excludedAddresses.value !== '') {
          // define array of excluded addresses in SLP format
          let excludedSlpAddresses = formData.excludedAddresses.value;
          // Remove spaces
          excludedSlpAddresses = excludedSlpAddresses.replace(/\s+/g, '');
          // If the last character is a comma, remove it
          if (
            excludedSlpAddresses.charAt(excludedSlpAddresses.length - 1) === ','
          ) {
            excludedSlpAddresses = excludedSlpAddresses.slice(0, -1);
          }
          // Parse for comma-separated list here
          excludedSlpAddresses = excludedSlpAddresses.split(',');
          // Iterate and convert to SLP format
          for (let i = 0; i < excludedSlpAddresses.length; i += 1) {
            excludedSlpAddresses[i] = SLP.Address.toSLPAddress(
              excludedSlpAddresses[i],
            );
          }
          let effectiveCirculatingSupply = tokenInfo.circulatingSupply;
          result.forEach((v, k) => {
            if (excludedSlpAddresses.includes(k)) {
              effectiveCirculatingSupply -= v;
              console.log(
                `Because ${k} is excluded, circulating supply is reduced by ${v} from ${tokenInfo.circulatingSupply} to ${effectiveCirculatingSupply}`,
              );
            }
          });
          // use effectiveCirculatingSupply to determine effectiveMinSlpBalanceForDividend

          const minSlpUnit = 1 / 10 ** airdropTokenInfo.decimals;
          // console.log(`minSlpUnit: ${minSlpUnit}`);
          const effectiveMinSlpBalanceForDividend =
            (minSlpUnit * effectiveCirculatingSupply) / dividendSlp;

          this.setState({
            effectiveMinSlpBalanceForDividend,
            effectiveCirculatingSupply,
          });

          result.forEach((v, k) => {
            if (
              v.lt(effectiveMinSlpBalanceForDividend) ||
              excludedSlpAddresses.includes(k)
            ) {
              result.delete(k);
            }
          });
        } else {
          result.forEach((v, k) => {
            if (v.lt(minSlpBalanceForDividend)) {
              result.delete(k);
            }
          });
        }

        const recipientCount = result.size;
        // Calculate many transactions will you need to pay this dividend, in batches of batchSize
        const txCount = Math.ceil(recipientCount / batchSize);
        // console.log(`Paying this airdrop will take ${txCount} transactions`);

        let appStatus = (
          <FormattedHTMLMessage
            id="slp-dividend-calculator.appStatus.queryComplete"
            values={{
              recipientCount,
            }}
          />
        );

        if (txCount > 1) {
          // console.log(`More than 22 outputs. Batching transactions...`);
          appStatus = (
            <FormattedHTMLMessage
              id="slp-dividend-calculator.appStatus.queryCompleteTxs"
              values={{
                recipientCount,
                txCount,
              }}
            />
          );
        }

        this.setState({
          appStatus,
        });

        // Determine the total supply of SLP tokens excluding holders with too small a balance to receive a dividend due to dust limit
        // You will divide by this to determine dividend payments -- to make sure you still pay the full dividend amount to all recipients
        const eligibleCirculatingSupply = Array.from(result.values()).reduce(
          (a, c) => a.plus(c),
          new Big(0),
        );
        // console.log(`eligibleCirculatingSupply: ${eligibleCirculatingSupply}`);
        // console.log(`token supplytoken supply: ${circulatingSupply}`);
        // const bch_amount = dividendSats / 1e8;
        const txOutputs = [];
        const txOutputsStrs = [];
        // console.time('forEach');
        // let totalSatsPaid = 0;
        result.forEach((v, k) => {
          const d = v.div(eligibleCirculatingSupply).mul(dividendSlp);

          // For SLP tx, you don't need to convert to BCH address, use the k result
          const slpRecevivingAddress = k; // SLP.Address.toLegacyAddress(k);

          // console.log(`${SLP.Address.toCashAddress(k)},`, d.toFixed(8));

          const txOutput = {};
          txOutput.address = slpRecevivingAddress;

          // Not sure how pay.bitcoin.com takes SLP amounts; try straight-up SLP

          // prev
          // txOutput.amount = +Math.floor(d * 1e8); // must be in sats for pay.bitcoin.com
          // SLP take 1

          txOutput.amount = d.toString(); // must be in sats for pay.bitcoin.com
          // totalSatsPaid += txOutput.amount;
          txOutputs.push(txOutput);

          const txOutputsStr = `${k.toString()},${d.toFixed(decimals)}`;
          txOutputsStrs.push(txOutputsStr);
        });
        // console.timeEnd('forEach');
        // console.log(`Total Sats Paid: ${totalSatsPaid}`);
        // console.log(`Total BCH paid: ${totalSatsPaid / 1e8}`);
        const outputTxStr = txOutputsStrs.join('\n');

        if (recipientCount === queryLimit) {
          const limitError = (
            <FormattedMessage
              id="slp-dividend-calculator.errors.limitError"
              values={{
                queryLimit,
              }}
            />
          );
          this.setState({
            limitError,
          });
        } else if (recipientCount === 0) {
          return this.setState({
            eligibleAddresses: recipientCount,
            limitError: formatMessage({
              id: 'slp-dividend-calculator.errors.noAddressesWithEnoughSlp',
            }),
            loading: false,
            txBuilt: true,
            appStatus: '',
          });
        } else {
          this.setState({
            outputTx: outputTxStr,
            eligibleAddresses: recipientCount,
            limitError: null,
          });
        }
        // console.log(txOutputs);
        // console.log(outputTxStr);

        // If your tx has more than 2500 outputs, you should split it into batches of no more than 2500 outputs each
        // This is to avoid a transaction larger than 100kb which most wallets would not send

        // How many transactions are needed?

        /*
        console.log(
          `Creating ${txCount} invoice(s) for ${txCount} transactions`,
        );
        */

        const invoicePromises = [];

        // Only create invoices if 25 or fewer transactions
        if (txCount <= 25) {
          for (let i = 0; i < txCount; i += 1) {
            // Invoice memo
            // TODO re-enable invoicememo when badger button is back
            // eslint-disable-next-line no-unused-vars
            let invoiceMemo = '';
            let txOutputsForTx = [];
            if (txCount === 1) {
              // Note: Choosing not to localize this string for now; unsure how some foreign characters could impact OP_RETURN fields
              // eslint-disable-next-line no-useless-escape
              invoiceMemo = `Paying ${dividendSlp} SLP Token ID \'${airdropTokenInfo.tokenId}\' aka \'${airdropTokenInfo.streetName}\' to holders of SLP Token ID \'${tokenId}\' aka \'${streetName}\' with a balance of at least ${minSlpBalanceForDividend} tokens (${txOutputs.length} total)`;
              txOutputsForTx = txOutputs;
            } else {
              invoiceMemo = `Transaction ${i +
                // Note: Choosing not to localize this string for now; unsure how some foreign characters could impact OP_RETURN fields
                // eslint-disable-next-line no-useless-escape
                1} of ${txCount}: Paying ${dividendSlp} total SLP Token ID \'${
                airdropTokenInfo.tokenId
                // eslint-disable-next-line no-useless-escape
              }\' aka \'${
                airdropTokenInfo.streetName
                // eslint-disable-next-line no-useless-escape
              }\' to holders of SLP Token ID \'${tokenId}\' aka \'${streetName}\' with a balance of at least ${minSlpBalanceForDividend} tokens (${
                txOutputs.length
              } total)`;
              const outputIndexStart = i * batchSize;
              let outputIndexEnd = outputIndexStart + batchSize;
              if (i === txCount) {
                outputIndexEnd = recipientCount - outputIndexStart;
              }
              txOutputsForTx = txOutputs.slice(
                outputIndexStart,
                outputIndexEnd,
              );
              // console.log(txOutputsForTx);
            }

            // promises go here
            invoicePromises[i] = fetch(
              'https://pay.bitcoin.com/create_invoice',
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  token_id: airdropTokenInfo.tokenId,
                  slp_outputs: txOutputsForTx,
                  memo: invoiceMemo,
                }),
              },
            ).then(response => response.json());
          }
        }

        // console.log(`invoicePromises:`)
        return this.setState(
          {
            appStatus,
          },
          this.parseInvoicePromises(invoicePromises),
        );
      },
      err => {
        console.log(`slpList.SlpdbQueries.GetAddressListFor(
          ${bestBlock},
          ${tokenId},
          ${slpdbUrl},
         )`);
        console.log(err);
        const slpdbError = (
          <FormattedMessage id="slp-dividend-calculator.errors.slpdbError" />
        );
        return this.setState({
          loading: false,
          slpdbError,
        });
      },
    );
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getBestBlockForSlpList(tokenInfo, minSlpBalanceForDividend, dividendSlp) {
    const { formData, selectedOption } = this.state;
    bitbox.Blockchain.getBlockchainInfo().then(
      blockchainInfo => {
        let bestBlock = blockchainInfo.blocks;

        // Check if there is a custom blockheight
        if (selectedOption === 'customBlockheight') {
          const inputCustomBlockheight = parseInt(
            formData.customBlockheight.value,
            10,
          );
          // If custom blockheight has not yet been mined, break loop  and show error
          if (inputCustomBlockheight > bestBlock) {
            formData.customBlockheight.error = (
              <FormattedMessage id="slp-dividend-calculator.errors.blockNotYetMined" />
            );
            return this.setState({
              formData,
              loading: false,
            });
          }
          // If custom blockheight is before generation block for this token, break loop and show error
          if (inputCustomBlockheight < tokenInfo.blockCreated) {
            formData.customBlockheight.error = (
              <FormattedMessage
                id="slp-dividend-calculator.errors.tokenDidNotExist"
                values={{
                  blockCreated: tokenInfo.blockCreated,
                  inputCustomBlockheight,
                }}
              />
            );
            return this.setState({
              formData,
              loading: false,
            });
          }
          bestBlock = inputCustomBlockheight;
          // console.log(`Using custom blockheight of ${bestBlock}`);
        }
        if (selectedOption === 'mempoolState') {
          bestBlock = -1;
        }
        return this.setState(
          {
            blockUsedInScan: bestBlock,
          },
          this.getTokenHolderAddresses(
            bestBlock,
            tokenInfo,
            minSlpBalanceForDividend,
            dividendSlp,
          ),
        );
      },
      err => {
        console.log(`Error in bitbox.Blockchain.getBlockchainInfo():`);
        console.log(err);
        return this.setState({
          loading: false,
        });
      },
    );
  }

  validateDividend = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData, airdropTokenInfo } = this.state;
    const minSlpUnit = 1 / 10 ** airdropTokenInfo.decimals;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;
    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.airdropNum',
      });
    } else if (value < minSlpUnit) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.airdropMin',
      });
    } else if (value > airdropTokenInfo.circulatingSupply) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.airdropMax',
      });
      // eslint-disable-next-line no-restricted-globals
    } else if (!isNaN(value) && value.toString().includes('.')) {
      if (value.toString().split('.')[1].length > airdropTokenInfo.decimals) {
        field.state = inputState.invalid;
        field.error = formatMessage({
          id: 'slp-dividend-calculator.errors.decimalsMax',
        });
      }
    }

    return field;
  };

  validateBlockheight = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;
    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.noBlockheight',
      });
    } else if (value < 543375) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.blockheightTooLow',
      });
    }

    return field;
  };

  handleTokenIdError = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.invalid;
    field.error = formatMessage({
      id: 'slp-dividend-calculator.errors.invalidTokenId',
    });

    return field;
  };

  validateTokenId = ({ name, value }) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    if (!value) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.required',
      });
    } else if (value.length !== 64) {
      field.state = inputState.invalid;
      field.error = formatMessage({
        id: 'slp-dividend-calculator.errors.invalidTokenId',
      });
    }
    return field;
  };

  validateExcludedAddresses = ({ name, value }) => {
    // Valid input is comma separated list of valid BCH, SLP, or legacy addresses

    const {
      intl: { formatMessage },
    } = this.props;

    const { formData } = this.state;

    const field = formData[name];

    field.value = value;
    field.state = inputState.valid;
    field.error = null;

    // Remove spaces
    let excludedAddressesNoSpaces = value.replace(/\s+/g, '');
    // If the last character is a comma, remove it
    if (
      excludedAddressesNoSpaces.charAt(excludedAddressesNoSpaces.length - 1) ===
      ','
    ) {
      excludedAddressesNoSpaces = excludedAddressesNoSpaces.slice(0, -1);
    }
    // Parse for comma-separated list here
    const excludedAddresses = excludedAddressesNoSpaces.split(',');

    // validation would go here
    if (value.length && excludedAddresses.length) {
      for (let i = 0; i < excludedAddresses.length; i += 1) {
        try {
          bitbox.Address.toLegacyAddress(excludedAddresses[i]);
        } catch (err) {
          field.state = inputState.invalid;
          field.error = formatMessage({
            id: 'slp-dividend-calculator.errors.addressInvalid',
          });
          return field;
        }
        // if valid, convert it to SLP
        // no, do this when user clicks to submit, you don't need to do this here
        // excludedAddresses[i] = SLP.Address.toSLPAddress(excludedAddresses[i]);
      }
    }
    return field;
  };

  updateWindowDimensions() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  // I don't understand this error msg as it applies to this function
  // eslint-disable-next-line class-methods-use-this
  copyUri(e) {
    document.getElementById(e.target.dataset.ref).select();
    document.execCommand('copy');
    // Show the the whole text area selected.
    document.getElementById(e.target.dataset.ref).focus();
  }

  // I don't understand this error msg as it applies to this function
  // eslint-disable-next-line class-methods-use-this
  copyToClipboard() {
    document.getElementById('txOutputArea').select();
    document.execCommand('copy');
    // Show the the whole text area selected.
    document.getElementById('txOutputArea').focus();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        calcError: null,
        appStatus: 'Collecting token info...',
        formStep: 'three',
      },
      window.scrollTo(0, 0),
    );
    const {
      // Keeping this here as I need to add localization strings for some msgs later TODO
      // eslint-disable-next-line no-unused-vars
      intl: { formatMessage },
    } = this.props;
    const { formData, airdropTokenInfo, slpdb } = this.state;
    const slpdbUrl = slpdb.value;
    if (formData.receivingSlpTokenId.state !== 1) {
      return;
    }

    const tokenId = formData.receivingSlpTokenId.value;
    const dividendSlp = formData.dividendSlp.value;

    // Get the supply of this token
    SLP.Utils.list(tokenId).then(
      // TODO split the SLPDB query into a separate function that is returned by the result of SLP.Utils.List(tokenId)
      // eslint-disable-next-line consistent-return
      result => {
        // console.log(result);

        const { blockCreated, decimals } = result;

        // get circulating supply from axios
        const q = {
          v: 3,
          q: {
            db: ['g'],
            aggregate: [
              {
                $match: {
                  'tokenDetails.tokenIdHex': tokenId,
                  'graphTxn.outputs': {
                    $elemMatch: {
                      status: 'UNSPENT',
                      slpAmount: { $gte: 0 },
                    },
                  },
                },
              },
              { $unwind: '$graphTxn.outputs' },
              {
                $match: {
                  'graphTxn.outputs.status': 'UNSPENT',
                  'graphTxn.outputs.slpAmount': { $gte: 0 },
                },
              },
              {
                $group: {
                  _id: null,
                  circulating_supply: {
                    $sum: '$graphTxn.outputs.slpAmount',
                  },
                },
              },
            ],
            limit: 100000,
          },
        };

        const data = Buffer.from(JSON.stringify(q)).toString('base64');

        const config = {
          method: 'GET',
          url: `${slpdbUrl}/q/${data}`,
        };

        axios(config).then(
          response => {
            // console.log(response);
            const circulatingSupply = parseFloat(
              response.data.g[0].circulating_supply,
            );
            /*
            console.log(
              `circulatingSupply from query 1 of 2: ${circulatingSupply}`,
            );
            */
            let streetName = result.name;
            // if the token has no name, use the symbol
            if (streetName === '') {
              streetName = result.symbol;
            }
            if (streetName === '') {
              streetName = '(no name or symbol)';
            }

            if (typeof circulatingSupply === 'undefined') {
              // Can get here with a bad token ID, endpoint returns array of all tokens

              console.log(`Token ID not recognized`);

              const calcError = (
                <FormattedMessage id="slp-dividend-calculator.errors.invalidIdCalcError" />
              );

              return this.setState(
                {
                  calcError,
                  loading: false,
                },
                this.throwTokenIdError(),
              );
            }

            // console.log(`circulatingSupply of receiving token: ${circulatingSupply}`)

            // log(`dustLimit: ${dustLimit}`)
            // log(`tokenSupply: ${tokenSupply}`)
            // log(`dividendSats: ${dividendSats}`)

            // What amount X such that x/(quantity of outstanding tokens)* dividendSats < dustLimit ? (for BCH)
            // Reworking for SLP...
            // What amount X such that x/(quantity of outstanding tokens)* dividendSlp < minSlpUnit
            // where minSlpUnit = 1/1**airdropSlpToken.decimals
            // console.log(`airdropTokenInfo.decimals: ${airdropTokenInfo.decimals}`);
            const minSlpUnit = 1 / 10 ** airdropTokenInfo.decimals;
            // console.log(`minSlpUnit: ${minSlpUnit}`);
            const minSlpBalanceForDividend =
              (minSlpUnit * circulatingSupply) / dividendSlp;

            /*
            console.log(
              `For this level of dividend payments (${dividendSlp} SLP) , the minimum token balance required to receive a dividend payment is ${minSlpBalanceForDividend} tokens.`,
            );
            */

            // Get all addresses with a token balance
            // return getTokenAddresses(minSlpBalanceForDividend, tokenSupply, utxos)
            const tokenInfo = {};
            tokenInfo.minSlpBalanceForDividend = minSlpBalanceForDividend;
            tokenInfo.circulatingSupply = circulatingSupply;
            tokenInfo.tokenId = tokenId;
            tokenInfo.streetName = streetName;
            tokenInfo.blockCreated = blockCreated;
            tokenInfo.decimals = decimals;
            const airdroppedStreetName = airdropTokenInfo.streetName;
            return this.setState(
              {
                tokenInfo,
                appStatus: (
                  <FormattedHTMLMessage
                    id="slp-dividend-calculator.appStatus.infoCollectedSlp"
                    values={{
                      streetName,
                      dividendSlp,
                      airdroppedStreetName,
                    }}
                  />
                ),
              },
              this.getBestBlockForSlpList(
                tokenInfo,
                minSlpBalanceForDividend,
                dividendSlp,
              ),
            );
          },
          err => {
            console.log(
              `Error in SLP.Utils.list(tokenId) in fountainhead slpdb query`,
            );
            console.log(err);
            // set state to show an error msg
            const calcError = (
              <FormattedMessage id="slp-dividend-calculator.errors.restError" />
            );
            return this.setState({
              calcError,
              loading: false,
            });
          },
        );
      },
      err => {
        console.log(`Error in SLP.Utils.list(tokenId):`);
        console.log(err);
        // set state to show an error msg
        const calcError = (
          <FormattedMessage id="slp-dividend-calculator.errors.restError" />
        );
        return this.setState({
          calcError,
          loading: false,
        });
      },
    );
  }

  handleAirdropSubmit(event) {
    event.preventDefault();
    this.setState({
      appStatus:
        'Collecting token info for token to be sent as a dividend payment...',
    });
    const {
      // Keeping this here as I need to add localization strings for some msgs later TODO
      // eslint-disable-next-line no-unused-vars
      intl: { formatMessage },
    } = this.props;
    const { formData, slpdb } = this.state;
    const slpdbUrl = slpdb.value;
    if (formData.airdropSlpTokenId.state !== 1) {
      return;
    }

    const tokenId = formData.airdropSlpTokenId.value;

    // Get the supply of this token
    SLP.Utils.list(tokenId).then(
      // TODO split the SLPDB query into a separate function that is returned by the result of SLP.Utils.List(tokenId)
      // eslint-disable-next-line consistent-return
      result => {
        // console.log(result);

        // Get circulating supply
        const { blockCreated, decimals } = result;

        // get circulating supply from axios
        const q = {
          v: 3,
          q: {
            db: ['g'],
            aggregate: [
              {
                $match: {
                  'tokenDetails.tokenIdHex': tokenId,
                  'graphTxn.outputs': {
                    $elemMatch: {
                      status: 'UNSPENT',
                      slpAmount: { $gte: 0 },
                    },
                  },
                },
              },
              { $unwind: '$graphTxn.outputs' },
              {
                $match: {
                  'graphTxn.outputs.status': 'UNSPENT',
                  'graphTxn.outputs.slpAmount': { $gte: 0 },
                },
              },
              {
                $group: {
                  _id: null,
                  circulating_supply: {
                    $sum: '$graphTxn.outputs.slpAmount',
                  },
                },
              },
            ],
            limit: 100000,
          },
        };

        const data = Buffer.from(JSON.stringify(q)).toString('base64');

        const config = {
          method: 'GET',
          url: `${slpdbUrl}/q/${data}`,
        };
        // console.log(config.url);

        axios(config).then(
          response => {
            // console.log(response);
            const circulatingSupply = parseFloat(
              response.data.g[0].circulating_supply,
            );
            // If circulatingSupply is null, throw an error
            if (circulatingSupply === null) {
              console.log(
                `Error in SLP.Utils.list(tokenId); circulatingSupply is null`,
              );

              // set state to show an error msg
              const calcError = (
                <FormattedMessage id="slp-dividend-calculator.errors.circulatingSupplyError" />
              );
              return this.setState({
                calcError,
                fetchingTokenInfo: false,
              });
            }

            let streetName = result.name;
            // if the token has no name, use the symbol
            if (streetName === '') {
              streetName = result.symbol;
            }
            if (streetName === '') {
              streetName = '(no name or symbol)';
            }

            if (typeof circulatingSupply === 'undefined') {
              // Can get here with a bad token ID, endpoint returns array of all tokens

              console.log(
                `Token ID of token you wish to airdrop is not recognized`,
              );

              const calcError = (
                <FormattedMessage id="slp-dividend-calculator.errors.invalidIdCalcError" />
              );

              return this.setState(
                {
                  calcError,
                  fetchingTokenInfo: false,
                },
                this.throwTokenIdError(),
              );
            }

            const airdropTokenInfo = {};
            airdropTokenInfo.circulatingSupply = circulatingSupply;
            airdropTokenInfo.tokenId = tokenId;
            airdropTokenInfo.streetName = streetName;
            airdropTokenInfo.blockCreated = blockCreated;
            airdropTokenInfo.decimals = decimals;
            return this.setState(
              {
                airdropTokenInfo,
                formStep: 'two',
                fetchingTokenInfo: false,
              },
              // console.log(airdropTokenInfo),
            );
          },
          err => {
            console.log(
              `Error in SLP.Utils.list(tokenId) for airdrop token in slpdb fountainhead query:`,
            );
            console.log(err);
            // set state to show an error msg
            const calcError = (
              <FormattedMessage id="slp-dividend-calculator.errors.restError" />
            );
            return this.setState({
              calcError,
              fetchingTokenInfo: false,
            });
          },
        );
      },
      err => {
        console.log(`Error in SLP.Utils.list(tokenId) for airdrop token:`);
        console.log(err);
        // set state to show an error msg
        const calcError = (
          <FormattedMessage id="slp-dividend-calculator.errors.restError" />
        );
        return this.setState({
          calcError,
          fetchingTokenInfo: false,
        });
      },
    );
  }

  // TODO re-enable this var when badger is enabled
  // eslint-disable-next-line no-unused-vars
  parseInvoicePromises(invoicePromises) {
    // console.log(`Entering parseInvoicePromises() with`)
    // console.log(invoicePromises);

    // Do not create invoices if more than 25 transactions are needed
    if (invoicePromises.length === 0) {
      return this.allInvoicesBuiltCheck();
    }

    Promise.all(invoicePromises).then(
      res => {
        // console.log(`Result of Promise.all(invoicePromises):`);
        // console.log(res);

        const paymentIds = [];
        for (let i = 0; i < res.length; i += 1) {
          paymentIds.push(res[i].paymentId);
        }

        return this.setState(
          {
            paymentIds,
          },
          this.allInvoicesBuiltCheck(),
        );
      },
      err => {
        console.log(`Error in Promise.all(invoicePromises):`);
        console.log(err);
        const apiError = (
          <FormattedMessage id="slp-dividend-calculator.errors.apiError" />
        );
        this.setState({
          loading: false,
          txBuilt: true,
          apiError,
        });
      },
    );

    return this.allInvoicesBuiltCheck();
  }

  allInvoicesBuiltCheck() {
    // Note: tried using this with the length of the PaymentIds state, but function (Even though callback) being called on un-updated paymentIds state
    // Confirm no undefined listings
    // console.log(`this.allInvoicesBuiltCheck()`)
    return this.setState({
      loading: false,
      txBuilt: true,
      appStatus: '',
    });
  }

  throwTokenIdError() {
    const { formData, formStep } = this.state;
    const { value } = formData.receivingSlpTokenId;
    let name = 'receivingSlpTokenId';
    if (formStep === 'one') {
      name = 'airdropSlpTokenId';
    }
    this.setState({
      fetchingTokenInfo: false,
      formData: {
        ...formData,
        [name]: this.handleTokenIdError({ name, value }),
      },
    });
  }

  handleRadioChange(e) {
    this.setState({
      selectedOption: e.target.value,
    });
  }

  handleDividendChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: { ...formData, [name]: this.validateDividend({ name, value }) },
    });
  }

  handleBlockheightChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: {
        ...formData,
        [name]: this.validateBlockheight({ name, value }),
      },
    });
  }

  handleTokenIdChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;

    this.setState({
      formData: { ...formData, [name]: this.validateTokenId({ name, value }) },
    });
  }

  handleExcludedAddressesChange(e) {
    const { value, name } = e.target;
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [name]: this.validateExcludedAddresses({ name, value }),
      },
    });
  }

  handleButtonClick() {
    // If form is valid
    const { formData } = this.state;
    if (
      formData.receivingSlpTokenId.state === 1 &&
      formData.dividendSlp.state === 1
    ) {
      this.setState({
        loading: true,
        txBuilt: false,
        apiError: null,
        paymentIds: [],
      });
    }
  }

  handleStepTwoButtonClick() {
    const { formData } = this.state;

    if (formData.airdropSlpTokenId.state === 1) {
      // Change text of button
      this.setState({
        calcError: null,
        fetchingTokenInfo: true,
      });
    }
  }

  reset() {
    this.setState(
      {
        formData: merge({}, this.initialFormData),
        txBuilt: false,
        toggleOutput: false,
        selectedOption: 'lastConfirmedBlock',
        airdropTokenInfo: merge({}, this.initialAirdropTokenInfo),
        formStep: 'one',
        invoicesExpired: '',
        limitError: null,
        apiError: null,
        calcError: null,
        slpdbError: null,
        outputTx: [],
        excludeAddresses: false,
      },
      window.scrollTo(0, 0),
    );
  }

  handleBackToStart() {
    // same as reset but keep form data
    this.setState(
      {
        txBuilt: false,
        toggleOutput: false,
        selectedOption: 'lastConfirmedBlock',
        formStep: 'two',
        invoicesExpired: '',
        limitError: null,
        apiError: null,
        calcError: null,
        slpdbError: null,
        outputTx: [],
      },
      window.scrollTo(0, 0),
    );
  }

  expireInvoices(err) {
    let errMsg = '';
    if (err.message.includes('denied transaction')) {
      errMsg = ``; // Canceled by user, no msg needed
    } else {
      errMsg = `BIP70 Invoice is expired.`;
    }
    this.setState({
      invoicesExpired: errMsg,
    });
  }

  handleSlpdbChange(selectedSlpdb) {
    this.setState({ slpdb: selectedSlpdb });
  }

  toggleAdvancedOptions() {
    const { toggleAdvanced } = this.state;
    this.setState({ toggleAdvanced: !toggleAdvanced });
  }

  toggleDividendOutput() {
    const { toggleOutput } = this.state;
    this.setState({ toggleOutput: !toggleOutput });
  }

  handleAddrExclude() {
    const { excludeAddresses } = this.state;
    this.setState({ excludeAddresses: !excludeAddresses });
  }

  render() {
    const {
      // I'm not sure how locale is used in the monorepo, keeping it here to match other templates
      // eslint-disable-next-line no-unused-vars
      locale,
      intl: { formatMessage },
    } = this.props;

    const {
      formData,
      airdropTokenInfo,
      tokenInfo,
      effectiveCirculatingSupply,
      effectiveMinSlpBalanceForDividend,
      eligibleAddresses,
      outputTx,
      txBuilt,
      loading,
      paymentIds,
      tab,
      limitError,
      apiError,
      calcError,
      appStatus,
      blockUsedInScan,
      selectedOption,
      formStep,
      fetchingTokenInfo,
      invoicesExpired,
      slpdb,
      toggleAdvanced,
      excludeAddresses,
      toggleOutput,
      slpdbError,
      windowWidth,
    } = this.state;
    const minSlpUnit = 1 / 10 ** airdropTokenInfo.decimals;
    const tabs = [
      {
        label: formatMessage({
          id: 'slp-dividend-calculator.tabs.invoice',
        }),
        action: () => this.setTabState(false),
      },
      {
        label: formatMessage({
          id: 'slp-dividend-calculator.tabs.electron',
        }),
        action: () => this.setTabState(true),
      },
    ];

    const progressMsg = <p>{appStatus}</p>;
    const errorDuringCalcMsg = <p>{calcError}</p>;

    const invoiceQrs = [];
    const invoiceTotal = paymentIds.length;
    let invoiceNumber = 1;

    // let sortedPaymentIdArr = []
    // let paymentIdArr = this.state.paymentIds

    // Sort so you present invoices in order
    if (paymentIds.length !== 0 && txBuilt) {
      // Iterate through invoices (you will have more than one if you have more than 2500 outputs)
      paymentIds.forEach(paymentId => {
        const invoiceUrl = `https://pay.bitcoin.com/i/${paymentId}`;
        const uriStr = `simpleledger:?r=https://pay.bitcoin.com/i/${paymentId}`;
        const titleStr = (
          <FormattedMessage
            id="slp-dividend-calculator.output.invoiceLabels"
            values={{
              invoiceNumber,
              invoiceTotal,
            }}
          />
        );
        invoiceQrs.push(
          <InvoiceTable key={invoiceUrl}>
            <p>{titleStr}</p>
            <Invoice
              sizeQR={windowWidth > 700 ? 250 : windowWidth / 3.2}
              copyUri
              linkAvailable={false}
              paymentRequestUrl={invoiceUrl}
              isRepeatable={false}
            />
            {/* <tbody>
              <tr>
                <BadgerTd colSpan="2">
                  <BadgerButton
                    text={titleStr}
                    paymentRequestUrl={invoiceUrl}
                    isRepeatable={false}
                    failFn={this.expireInvoices}
                  />
                </BadgerTd>
              </tr>
              <tr>
                <TruncatedTD>

                  <UriTxtArea rows="4" id={paymentId} value={uriStr} readOnly />
                </TruncatedTD>
                <CopyUriTd>
                  <CtrlC data-ref={paymentId} onClick={this.copyUri}></CtrlC>
                </CopyUriTd>
              </tr>
              <tr>
                <td colSpan="2">
                  <InputError show>{invoicesExpired}</InputError>
                </td>
              </tr>
            </tbody> */}
          </InvoiceTable>,
        );
        invoiceNumber += 1;
      });
    }
    let batchingAlert = '';
    if (paymentIds.length > 1) {
      batchingAlert = (
        <FormattedMessage id="slp-dividend-calculator.airdropOutput.batchingAlert" />
      );
    }

    return (
      <React.Fragment>
        <CustomContentBlock hero noPadding>
          {formStep === 'one' && (
            <React.Fragment>
              <Form
                id="selectTokenToAirdrop"
                onSubmit={this.handleAirdropSubmit}
              >
                <InputWrapper show>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.airdroppedSlpTokenId" />{' '}
                    <Red>*</Red>
                  </InputLabel>
                  <Input
                    id="inputAirdropSlpTokenId"
                    name="airdropSlpTokenId"
                    type="string"
                    value={formData.airdropSlpTokenId.value}
                    onChange={this.handleTokenIdChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.airdroppedSlpTokenId',
                    })}
                    required
                  />
                </InputWrapper>
                <InputError show>{formData.airdropSlpTokenId.error}</InputError>
              </Form>
              <Button
                type="submit"
                form="selectTokenToAirdrop"
                primary
                onClick={this.handleStepTwoButtonClick}
              >
                {fetchingTokenInfo ? (
                  <FormattedMessage id="slp-dividend-calculator.buttons.fetching" />
                ) : (
                  <FormattedMessage id="slp-dividend-calculator.buttons.next" />
                )}
              </Button>
            </React.Fragment>
          )}
          {formStep === 'two' && (
            <React.Fragment>
              <b>Token to be airdropped:</b>
              &quot;{airdropTokenInfo.streetName}&quot; <br />
              <b>Token decimal places:</b> {airdropTokenInfo.decimals} <br />
              <CustomOL start="2">
                <li>
                  <FormattedMessage id="slp-dividend-calculator.airdropInstructions.stepTwo" />
                </li>
              </CustomOL>
              <Form id="submit-form" onSubmit={this.handleSubmit}>
                <InputWrapper show>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.receivedSlpTokenId" />{' '}
                    <Red>*</Red>
                  </InputLabel>
                  <Input
                    id="inputReceivingSlpTokenId"
                    name="receivingSlpTokenId"
                    type="string"
                    value={formData.receivingSlpTokenId.value}
                    onChange={this.handleTokenIdChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.receivedSlpTokenId',
                    })}
                    required
                  />
                </InputWrapper>
                <InputError show>
                  {formData.receivingSlpTokenId.error}
                </InputError>
                <InputWrapper show>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.airdropSlp" />{' '}
                    <Red>*</Red>
                  </InputLabel>
                  <Input
                    id="inputdividendSlp"
                    name="dividendSlp"
                    type="number"
                    min={minSlpUnit}
                    step={minSlpUnit}
                    value={formData.dividendSlp.value}
                    onChange={this.handleDividendChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.airdropSlp',
                    })}
                    required
                  />
                </InputWrapper>
                <InputError show>{formData.dividendSlp.error}</InputError>
                <InputWrapper show>
                  <Checkbox onChange={this.handleAddrExclude}></Checkbox>
                  <CheckLabel>Exclude addresses?</CheckLabel>
                </InputWrapper>
                <InputWrapper show={excludeAddresses}>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.labels.excludeAddresses" />{' '}
                  </InputLabel>
                  <Input
                    id="excludeAddr"
                    name="excludedAddresses"
                    type="string"
                    value={formData.excludedAddresses.value}
                    onChange={this.handleExcludedAddressesChange}
                    placeholder={formatMessage({
                      id: 'slp-dividend-calculator.input.excludeAddresses',
                    })}
                  />
                </InputWrapper>
                <InputError show={excludeAddresses}>
                  {formData.excludedAddresses.error}
                </InputError>
                <InputWrapper show>
                  <CustomLink show onClick={this.toggleAdvancedOptions}>
                    <FormattedMessage id="slp-dividend-calculator.labels.toggleAdvanced" />
                  </CustomLink>
                </InputWrapper>
                {toggleAdvanced && (
                  <React.Fragment>
                    <InputWrapper show>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.blockchainState" />{' '}
                        <Red>*</Red>
                      </InputLabel>
                      <Label>
                        <FormattedMessage
                          tagName="div"
                          id="slp-dividend-calculator.radio.lastConfirmed"
                        />
                        <input
                          type="radio"
                          value="lastConfirmedBlock"
                          checked={selectedOption === 'lastConfirmedBlock'}
                          onChange={this.handleRadioChange}
                        />
                        <span />
                      </Label>
                      {/* <Label>
                    <FormattedMessage
                      tagName="div"
                      id="slp-dividend-calculator.radio.mempool"
                    />
                    <input
                      type="radio"
                      value="mempoolState"
                      checked={selectedOption === 'mempoolState'}
                      onChange={this.handleRadioChange}
                    />
                    <span />
                  </Label> */}
                      <Label>
                        <FormattedMessage
                          tagName="div"
                          id="slp-dividend-calculator.radio.customBlockheight"
                        />
                        <input
                          type="radio"
                          value="customBlockheight"
                          checked={selectedOption === 'customBlockheight'}
                          onChange={this.handleRadioChange}
                        />
                        <span />
                      </Label>
                    </InputWrapper>
                    <InputWrapper show={selectedOption === 'customBlockheight'}>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.customBlockheight" />{' '}
                        <Red>*</Red>
                      </InputLabel>
                      <Input
                        disabled={selectedOption !== 'customBlockheight'}
                        id="inputCustomBlockheight"
                        name="customBlockheight"
                        type="number"
                        min="543375"
                        step="1"
                        value={formData.customBlockheight.value}
                        onChange={this.handleBlockheightChange}
                        placeholder={formatMessage({
                          id: 'slp-dividend-calculator.input.customBlockheight',
                        })}
                        required
                      />
                    </InputWrapper>
                    <InputError show={selectedOption === 'customBlockheight'}>
                      {formData.customBlockheight.error}
                    </InputError>
                    <InputWrapper show>
                      <InputLabel>
                        <FormattedMessage id="slp-dividend-calculator.labels.slpdbSelect" />{' '}
                      </InputLabel>
                      <CustomSelect
                        options={[
                          {
                            value: 'https://slpdb.bitcoin.com',
                            label: 'slpdb.bitcoin.com',
                          },
                          {
                            value: 'https://slpdb.fountainhead.cash',
                            label: 'slpdb.fountainhead.cash',
                          },
                        ]}
                        value={slpdb}
                        onChange={this.handleSlpdbChange}
                      />
                    </InputWrapper>
                  </React.Fragment>
                )}
              </Form>
            </React.Fragment>
          )}
        </CustomContentBlock>
        <ProgressContainer show={loading}>
          <CustomContentBlock hero noPadding>
            {progressMsg}
          </CustomContentBlock>
        </ProgressContainer>
        <ErrorContainer show={calcError !== null}>
          <CustomContentBlock hero noPadding>
            {errorDuringCalcMsg}
          </CustomContentBlock>
        </ErrorContainer>
        {formStep === 'two' && (
          <CustomContentBlock hero>
            <Buttons>
              <Button
                type="submit"
                form="submit-form"
                primary
                onClick={this.handleButtonClick}
              >
                {loading ? (
                  <FormattedMessage id="slp-dividend-calculator.buttons.loading" />
                ) : (
                  <FormattedMessage id="slp-dividend-calculator.buttons.submit" />
                )}
              </Button>
              <Button type="button" onClick={this.reset}>
                <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
              </Button>
            </Buttons>
          </CustomContentBlock>
        )}
        {formStep === 'three' && txBuilt && (
          <Buttons>
            <Button primary type="button" onClick={this.toggleDividendOutput}>
              {toggleOutput === true ? (
                <FormattedMessage id="slp-dividend-calculator.buttons.hideDetails" />
              ) : (
                <FormattedMessage id="slp-dividend-calculator.buttons.showDetails" />
              )}
            </Button>
            <Button dark type="button" onClick={this.reset}>
              <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
            </Button>
          </Buttons>
        )}

        <DividendDetails show={toggleOutput}>
          <CustomContentBlock hero noPadding>
            <H3>
              <FormattedMessage id="slp-dividend-calculator.airdropOutput.title" />
            </H3>

            <OutputWrapper>
              <b>
                <FormattedMessage id="slp-dividend-calculator.airdropOutput.blockUsedInScan" />
              </b>{' '}
              {blockUsedInScan === null
                ? blockUsedInScan
                : blockUsedInScan.toLocaleString()}{' '}
              <br />
              <b>
                <FormattedMessage id="slp-dividend-calculator.airdropOutput.dividendPayment" />
              </b>{' '}
              {formData.dividendSlp.value} {airdropTokenInfo.streetName} <br />
              <b>
                <FormattedMessage id="slp-dividend-calculator.airdropOutput.airdroppedId" />
              </b>{' '}
              {airdropTokenInfo.tokenId} aka &quot;{airdropTokenInfo.streetName}
              &quot; <br />
              <b>
                <FormattedMessage id="slp-dividend-calculator.airdropOutput.tokenInfo" />
              </b>{' '}
              {tokenInfo.tokenId} aka &quot;{tokenInfo.streetName}&quot; <br />
              {effectiveCirculatingSupply !== 0 ? (
                <>
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.output.effectiveCirculatingSupply" />
                  </b>{' '}
                  {effectiveCirculatingSupply.toLocaleString()}{' '}
                  {tokenInfo.streetName}
                  <br />
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.airdropOutput.minSlp" />
                  </b>{' '}
                  {effectiveMinSlpBalanceForDividend.toFixed(8)}{' '}
                  {tokenInfo.streetName}
                  <br />
                </>
              ) : (
                <>
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.airdropOutput.circulatingSupply" />
                  </b>{' '}
                  {tokenInfo.circulatingSupply.toLocaleString()}{' '}
                  {tokenInfo.streetName}
                  <br />
                  <b>
                    <FormattedMessage id="slp-dividend-calculator.airdropOutput.minSlp" />
                  </b>{' '}
                  {tokenInfo.minSlpBalanceForDividend.toFixed(8)}{' '}
                  {tokenInfo.streetName}
                  <br />
                </>
              )}
              <Small>
                <FormattedMessage
                  id="slp-dividend-calculator.airdropOutput.minSlpAirdropNote"
                  values={{
                    minTokenBalance: tokenInfo.minSlpBalanceForDividend.toFixed(
                      airdropTokenInfo.decimals,
                    ),
                    streetName: tokenInfo.streetName,
                    minSlpUnit,
                    airdropName: airdropTokenInfo.streetName,
                  }}
                />
              </Small>
              <p>
                <FormattedMessage
                  id="slp-dividend-calculator.airdropOutput.eligibleMsg"
                  values={{ eligibleAddresses }}
                />
                {batchingAlert}
              </p>
            </OutputWrapper>
          </CustomContentBlock>
        </DividendDetails>
        <InputError show>{limitError}</InputError>
        <InputError show>{apiError}</InputError>
        <InputError show={slpdbError !== null && !loading}>
          {slpdbError}
        </InputError>
        {slpdbError !== null && !loading && (
          <RetryButtons>
            <Button primary onClick={this.handleBackToStart}>
              <FormattedMessage id="slp-dividend-calculator.buttons.tryAgain" />
            </Button>
            <Button type="button" onClick={this.reset}>
              <FormattedMessage id="slp-dividend-calculator.buttons.reset" />
            </Button>
          </RetryButtons>
        )}
        <Feedback show={txBuilt}>
          <Content>
            <Tabs selectedTab={tab === false ? 0 : 1} tabs={tabs} />
            {tab === true ? (
              <>
                <OutputWrapper>
                  <center>
                    <H3>
                      {document.queryCommandSupported('copy') && (
                        <Button primary onClick={this.copyToClipboard}>
                          <FormattedMessage id="slp-dividend-calculator.airdropOutput.copyBtn" />
                        </Button>
                      )}
                    </H3>
                  </center>
                  <InputLabel>
                    <FormattedMessage id="slp-dividend-calculator.airdropOutput.outputLabels" />
                  </InputLabel>
                  <TextArea
                    id="txOutputArea"
                    value={outputTx}
                    readOnly
                  ></TextArea>
                </OutputWrapper>
              </>
            ) : (
              <React.Fragment>
                <Instructions>
                  {paymentIds.length > 0 ? (
                    <FormattedHTMLMessage id="slp-dividend-calculator.airdropOutput.scanInstructions" />
                  ) : (
                    <FormattedMessage id="slp-dividend-calculator.airdropOutput.tooManyTx" />
                  )}
                </Instructions>

                <InvoiceWrapper>{invoiceQrs}</InvoiceWrapper>
              </React.Fragment>
            )}
          </Content>
        </Feedback>
      </React.Fragment>
    );
  }
}

Airdropper.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(Airdropper);
// Comment
